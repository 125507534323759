/* eslint-disable security/detect-object-injection */
import { AttributeValue, AttributeValueBase } from '@msdyn365-commerce/retail-proxy';

export interface IBtachAttribute {
    InventBatchId: string | undefined;
    ConsumedQuantity: string | undefined;
}
export interface IBatchCache {
    originalQuantity: number;
    productID: number | undefined;
    batchAttribute: IBtachAttribute[] | null | undefined;
}
export interface IProductAttributeRecordIds {
    segmentAttributeRecordId?: number;
    collectionAttributeRecordId?: number;
    colourCardAttributeRecordId?: number;
    treatmentAttributeRecordId?: number;
    spinningTechAttributeRecordId?: number;
    countAttributeRecordId?: number;
    twistAttributeRecordId?: number;
    turnAttributeRecordId?: number;
    guageAttributeRecordId?: number;
    compositionAttributeRecordId?: number;
    micronAttributeRecordId?: number;
    stockSupportedAttributeRecordId?: number;
    availabilityAttributeRecordId?: number;
    certificatesAttributeRecordId?: number;
    careLabelAttributeRecordId?: number;
    waxedAttributeRecordId?: number;
    nominalCountAttributeRecordId?: number;
    colorTypeAttributeRecordId?: number;
    businessModeAttributeRecordId?: number;
    bobbinAttributeRecordId?: number;
}
export const findProductAttributes = (attributeRecordIds: IProductAttributeRecordIds, attributes?: AttributeValue[]) => {
    const segmentAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.segmentAttributeRecordId);
    const collectionAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.collectionAttributeRecordId);
    const colourCardAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.colourCardAttributeRecordId);
    const treatmentAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.treatmentAttributeRecordId);
    const spinningTechAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.spinningTechAttributeRecordId);
    const countAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.countAttributeRecordId);
    const twistAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.twistAttributeRecordId);
    const turnAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.turnAttributeRecordId);
    const guageAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.guageAttributeRecordId);
    const compositionAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.compositionAttributeRecordId);
    const micronAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.micronAttributeRecordId);
    const stockSupportedAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.stockSupportedAttributeRecordId);
    const availabilityAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.availabilityAttributeRecordId);
    const waxedAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.waxedAttributeRecordId);
    const certificatesAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.certificatesAttributeRecordId);
    const careLabelAttribute = attributes?.find((attr: AttributeValue) => attr.RecordId === attributeRecordIds.careLabelAttributeRecordId);
    const colorTypeAttribute = attributes?.find((attr: AttributeValue) => attr.RecordId === attributeRecordIds.colorTypeAttributeRecordId);
    const businessModeAttribute = attributes?.find(
        (attr: AttributeValue) => attr.RecordId === attributeRecordIds.businessModeAttributeRecordId
    );
    const nominalCountAttribute = attributes?.find(
        (attr: AttributeValue) => attr.RecordId === attributeRecordIds.nominalCountAttributeRecordId
    );
    const bobbianAttribute = attributes?.find(attr => attr.RecordId === attributeRecordIds.bobbinAttributeRecordId);

    
    return {
        segmentAttribute,
        collectionAttribute,
        colourCardAttribute,
        treatmentAttribute,
        spinningTechAttribute,
        countAttribute,
        twistAttribute,
        turnAttribute,
        guageAttribute,
        compositionAttribute,
        micronAttribute,
        stockSupportedAttribute,
        availabilityAttribute,
        certificatesAttribute,
        careLabelAttribute,
        waxedAttribute,
        nominalCountAttribute,
        colorTypeAttribute,
        businessModeAttribute,
        bobbianAttribute
    };
};

// List of customer fields to hide from my profile page
export const additionalCustomerFields = [
    'ID',
    'ExtensionProperties',
    'PartyRecId',
    'AddressRecId',
    'DeliveryPhoneRecId',
    'PrimaryContactFaxRecId',
    'OrgPrimaryFaxRecId',
    'OrgPrimaryPhoneRecId',
    'OrgPrimaryURLRecId',
    'PrimaryContactPhoneRecId',
    'Salutation',
    'FirstName',
    'Status',
    'DeliveryName',
    'DeliveryStreet',
    'DeliveryCountry',
    'DeliveryZip',
    'DeliveryCity',
    'DilveryContactNumber'
];

//function to convertFirstLetterToLowerCase of a string.
export function convertFirstLetterToLowerCase(text: string) {
    return text.charAt(0).toLowerCase() + text.slice(1);
}

// Regex to remove all special characters from Product Item Code (e.g. VICTORIA,NM 30/2,S,W)
export const itemCodeRegex = /[^\w\s]/gi;

// Function to calculate % value of a number (e.g 20% of 10 = 2)
export const percentageValue = (total: number, percentage: number) => Math.ceil((total / 100) * percentage);

// Function to check if attribute exists then update value else add new attribute
export const addAttribute = (currentAttributes: AttributeValue[], attributeObj: AttributeValue): AttributeValue[] => {
    const attrIndex = currentAttributes.findIndex((attr: AttributeValue) => attr.Name?.toLowerCase() === attributeObj.Name?.toLowerCase());
    if (attrIndex > -1) {
        currentAttributes[attrIndex] = attributeObj;
    } else {
        currentAttributes.push(attributeObj);
    }
    return currentAttributes;
};

// Function to create attribute object
export const _makeAttribute = (AttributeName: string, attributeValue: string | undefined) => {
    const attributeObj = {
        // @ts-ignore
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: AttributeName,
        TextValue: attributeValue,
        TextValueTranslations: []
    };
    return attributeObj;
};
export const _makeExtentionProperty = (key: string, value: string) => {
    const extentionPropertyObj = [
        {
            Key: key,
            Value: { StringValue: value }
        }
    ];
    return extentionPropertyObj;
};

export const getBatchRecordUtility = (): IBatchCache[] | undefined => {
    const result = localStorage.getItem('sw_BatchRecord');
    return JSON.parse(result!);
};

export const setBatchRecordUtility = (obj: IBatchCache) => {
    const oldBatchRecord = getBatchRecordUtility();
    if (oldBatchRecord) {
        // console.log('oldBatchRecord',oldBatchRecord);
        const newBatchRecord =
            oldBatchRecord &&
            oldBatchRecord?.map(item => {
                if (item.productID === obj.productID) {
                    return {
                        ...item,
                        originalQuantity: item.originalQuantity + obj.originalQuantity,
                        batchAttribute: obj.batchAttribute
                    };
                } else {
                    return item;
                }
            });
        // console.log('after 2nd update BatchRecord',newBatchRecord);
        return localStorage.setItem('sw_BatchRecord', JSON.stringify(newBatchRecord));
    } else {
        // console.log('first update BatchRecord',JSON.stringify([obj]));
        return localStorage.setItem('sw_BatchRecord', JSON.stringify([obj]));
    }
};
export const removeBatchRecordUtility = () => {
    return localStorage.removeItem('sw_BatchRecord');
};

export const splitQuantityThresholdMessage = (value?: string, locale?: string) => {
    let quantityThresholdMessagesArray: string[] = [];
    switch (locale) {
        case 'it':
            const it =
                'Hai ordinato _ kg e noi aggiungeremo _ ulteriori kg a scopo di pagamento. Non ti verrà addebitato in fase di fatturazione.';
            quantityThresholdMessagesArray = it.split('_');
            break;
        case 'de':
            const de =
                'Du hast _ kg bestellt und wir fügen _ zusätzliches kg zu Zahlungszwecken hinzu. Diese wird bei Rechnungsstellung nicht verrechnet.';
            quantityThresholdMessagesArray = de.split('_');
            break;
        default:
            const en =
                'You ordered _ kg and we’ll add _ additional kg for payment purposes. It will not be charged to you at invoicing.';
            quantityThresholdMessagesArray = value ? value.split('_') : en.split('_');
            break;
    }
    return {
        thresholdPrefix: quantityThresholdMessagesArray[0],
        thresholdContent: quantityThresholdMessagesArray[1],
        thresholdPostfix: quantityThresholdMessagesArray[2]
    };
};

export const getAttributeTextValue = (attributes: AttributeValueBase[], name: string) => {
    if (attributes?.length > 0) {
        const quantityThreshold = attributes.find(cartLineData => {
            return cartLineData.Name === name;
        });
        // @ts-ignore
        return quantityThreshold.TextValue;
    } else {
        return 0;
    }
};

export const getQuantityThreshold = (qty : number, upperWeightThresholdKG : number) => {
    const quantityThreshold = qty >=0 && qty <=upperWeightThresholdKG ? true : false;
    return quantityThreshold;
}
